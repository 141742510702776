import React, { Component } from 'react';
import {Helmet} from "react-helmet";

import mertburoMockup from '../../images/referans/mert-buro-mockup.jpg';

export default class Mertburo extends Component {
  render() {
    return (
      <section>
				<Helmet>
					<title>Mert Büro | Mert Sistem</title>
					<meta name="description" content="" />
				</Helmet>
				<div className="container-fluid single-blog-post">
					<div className="container">
						<h1>
              Mert Büro
						</h1>
            <img src={mertburoMockup} alt="mert-buro-mockup"/>
            <p>

            </p>
					</div>
				</div>
			</section>
    )
  }
}
