import React, { Component } from 'react'
import {Helmet} from "react-helmet";

export default class Error extends Component {
  render() {
    return (
      <section>
				<Helmet>
					<title>Hata | Mert Sistem</title>
					<meta name="description" content="Böyle bir sayfa bulunamadı. Zaten hiç vâr olmamış olabilir." />
				</Helmet>
				<div className="container-fluid featured-works">
					<div className="container">
						<h1>
							Böyle bir sayfa bulunamadı.
						</h1>
					</div>
				</div>
			</section>
    )
  }
}
