import React, { Component } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  // Axios Post >

  state = {
    name: "",
    company: "",
    email: "",
    phone: "",
    details: "",
    budget: "",
    time: "",
    website: true,
    mobile: true,
    seo: true,
    design: true
  };

  handleChangeName = event => {
    this.setState({ name: event.target.value });
  };

  handleChangeCompany = event => {
    this.setState({ company: event.target.value });
  };

  handleChangeEmail = event => {
    this.setState({ email: event.target.value });
  };

  handleChangePhone = event => {
    this.setState({ phone: event.target.value });
  };

  handleChangeDetails = event => {
    this.setState({ details: event.target.value });
  };

  handleChangeBudget = event => {
    this.setState({ budget: event.target.value });
  };

  handleChangeTime = event => {
    this.setState({ time: event.target.value });
  };

  handleChangeWebsite = event => {
    this.setState({ website: event.target.checked });
  };

  handleChangeMobile = event => {
    this.setState({ mobile: event.target.checked });
  };

  handleChangeSeo = event => {
    this.setState({ seo: event.target.checked });
  };

  handleChangeDesing = event => {
    this.setState({ design: event.target.checked });
  };

  handleSubmit = event => {
    event.preventDefault();

    const form = {
      name: this.state.name,
      company: this.state.company,
      email: this.state.email,
      phone: this.state.phone,
      details: this.state.details,
      budget: this.state.budget,
      time: this.state.time,
      service_website: this.state.website,
      service_mobile: this.state.mobile,
      service_seo: this.state.seo,
      service_design: this.state.design
    };

    axios
      .post(process.env.REACT_APP_OFFER_FORM, { form })
      .then(function(res) {
        if (res.err) {
          console.log("Something went wrong!!! => Error: " + res.err.message);
        } else {
          console.log(res);
        }
      })
      .catch(err => {
        console.log("Something went wrong!!! => Error: " + err.message);
      });
  };

  // < Axios Post

  render() {
    return (
      <section>
        <Helmet>
          <title>İletişim | Mert Sistem</title>
          <meta
            name="description"
            content="Bize projeni anlat sana neler yapamayacağımızı söyleyelim. Genellikle çok kısa sürede dönüş yapıyoruz."
          />
        </Helmet>
        <div className="container main-hero">
          <div>
            <h1>
              İletişim Çağındayız, <br />
              Platonik Aşka İnanmıyoruz.
            </h1>
            <p>
              Bize projeni anlat sana neler yapamayacağımızı söyleyelim.
              Genellikle çok kısa sürede dönüş yapıyoruz.
            </p>
          </div>
        </div>
        <div className="container-fluid contact-page">
          <div className="container">
            <div className="title">Hizmetler</div>
            <form name="contact" method="POST"  data-netlify="true" netlify>
              <ul className="options">
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.website}
                      name="website"
                      onChange={this.handleChangeWebsite}
                    />
                    <span>Website</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.mobile}
                      name="mobile"
                      onChange={this.handleChangeMobile}
                    />
                    <span>Mobil Uygulama</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.seo}
                      name="seo"
                      onChange={this.handleChangeSeo}
                    />
                    <span>SEO/SEM</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.design}
                      name="desing"
                      onChange={this.handleChangeDesing}
                    />
                    <span>Tasarım</span>
                  </label>
                </li>
              </ul>
              <div className="title">Süre</div>
              <ul className="options">
                <li>
                  <label>
                    <input
                      type="radio"
                      value="1"
                      name="time"
                      onChange={this.handleChangeTime}
                    />
                    <span>1 Hafta</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      value="2"
                      name="time"
                      onChange={this.handleChangeTime}
                    />
                    <span>3 Hafta</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      value="3"
                      name="time"
                      onChange={this.handleChangeTime}
                    />
                    <span>4+ Hafta</span>
                  </label>
                </li>
              </ul>
              <div className="title">Bütçe</div>
              <ul className="options">
                <li>
                  <label>
                    <input
                      type="radio"
                      value="1"
                      name="budget"
                      onChange={this.handleChangeBudget}
                    />
                    <span>2500TL</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      value="2"
                      name="budget"
                      onChange={this.handleChangeBudget}
                    />
                    <span>5000TL</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      value="3"
                      name="budget"
                      onChange={this.handleChangeBudget}
                    />
                    <span>5000TL+</span>
                  </label>
                </li>
              </ul>
              <div className="form form-2">
                <div className="input-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    onChange={this.handleChangeName}
                    placeholder=" "
                    required
                  />
                  <label>İsim*</label>
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    name="phone"
                    onChange={this.handleChangePhone}
                    placeholder=" "
                  />
                  <label>Telefon Numarası</label>
                </div>
              </div>
              <div className="form form-2">
                <div className="input-group">
                  <input
                    type="email"
                    name="email"
                    onChange={this.handleChangeEmail}
                    placeholder=" "
                    required
                  />
                  <label>Mail Adresiniz*</label>
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    name="company"
                    onChange={this.handleChangeCompany}
                    placeholder=" "
                  />
                  <label>Şirket İsmi</label>
                </div>
              </div>
              <div className="form form-1">
                <div className="input-group">
                  <input
                    type="text"
                    name="details"
                    onChange={this.handleChangeDetails}
                    placeholder=" "
                  />
                  <label>Detaylar</label>
                </div>
              </div>
              <button type="submit" className="btn btn-red">
                Teklif Al
              </button>
            </form>
          </div>
        </div>
      </section>
    );
  }
}
