import React, { Component } from 'react';
import './css/app.sass';
import './js/app.js';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {Helmet} from "react-helmet";

// Components
import Header from './components/Header'
import Footer from './components/Footer'
import ScrollToTop from './components/ScrollToTop.js'

// Pages
import Home from './pages/Home'
import About from './pages/About'
import Works from './pages/Works'
import Next from './pages/Next'
import Contact from './pages/Contact'
import Error from './pages/Error'
// Blogs
import Post1 from './pages/blog/post1'
import Post2 from './pages/blog/post2'
// Referanslar
import Akkaymak from './pages/works/akkaymak'
import Mertburo from './pages/works/mertburo'

class App extends Component {
  render() {
    return (

      <Router>
        <Helmet>
          {/* <!-- Primary Meta Tags --> */}
          <title>Mert Sistem | Ankara Web Tasarım ve Dijital Danışmanlık</title>
          <meta name="title" content="Mert Sistem | Ankara Web Tasarım ve Dijital Danışmanlık" />
          <meta name="description" content="Ankara’da işinize değer katan Web Tasarım ve Dijital Çözümler üreten güvenilir iş ortağınız. " />

          {/* <!-- Open Graph / Facebook --> */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://mertsistem.com/" />
          <meta property="og:title" content="Mert Sistem | Ankara Web Tasarım ve Dijital Danışmanlık" />
          <meta property="og:description" content="Ankara’da işinize değer katan Web Tasarım ve Dijital Çözümler üreten güvenilir iş ortağınız. " />
          <meta property="og:image" content="https://mertsistem.com/static/media/hero-images.c9c24f08.svg" />

          {/* <!-- Twitter --> */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://mertsistem.com/" />
          <meta property="twitter:title" content="Mert Sistem | Ankara Web Tasarım ve Dijital Danışmanlık" />
          <meta property="twitter:description" content="Ankara’da işinize değer katan Web Tasarım ve Dijital Çözümler üreten güvenilir iş ortağınız. " />
          <meta property="twitter:image" content="https://mertsistem.com/static/media/hero-images.c9c24f08.svg" />

        </Helmet>
        <Header />
        <ScrollToTop> 
          <Switch>
            <Route path="/" exact strict component={Home} />
            <Route path="/tanisalim" exact strict component={About} />
            <Route path="/neler-yaptik" exact strict component={Works} />
            <Route path="/heycanlaniyoruz" exact strict component={Next} />
            <Route path="/iletisim" exact strict component={Contact} />

            {/* Blog Posts */}
            <Route path="/growth-hacking-ile-buyume" exact strict component={Post1} />
            <Route path="/e-ticaret-yapmiyorum-ssl-ihtiyaci" exact strict component={Post2} />

            {/* Works */}
            <Route path="/akkaymak-kirtasiye" exact strict component={Akkaymak} />
            <Route path="/mert-buro" exact strict component={Mertburo} />

            <Route component={Error} />
          </Switch>
        </ScrollToTop>
        <Footer />
      </Router>
    );
  }
}


export default App;
