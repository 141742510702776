import React, { Component } from 'react'
import logo from '../images/logo.svg';
import behance from '../images/social/behance.svg';
import facebook from '../images/social/facebook.svg';
import instagram from '../images/social/instagram.svg';
import linkedin from '../images/social/linkedin.svg';
// import twitter from '../images/social/twitter.svg';
import youtube from '../images/social/youtube.svg';

import { NavLink } from 'react-router-dom';

export default class Footer extends Component {
  render() {
    return (
      <footer className="container footer">
        <div className="footer-top">
          <div className="footer-left">
            <NavLink to="/">
              <img src={logo} className="footer-logo" alt="logo" />
            </NavLink>
            <p className="footer-left-text">
              Değer katacağımıza inanıyoruz! Belki sizi uzaya çıkarabilecek teknolojiye sahip değiliz ama sizi en yakın uzay istasyonuna bırakabiliriz. 
            </p>
          </div>
          <div className="footer-right text-right">
            <h2>Hadi Konuşalım <span role="img" aria-label="peace">✌️</span></h2>
            <p>Platonik aşka inanmıyoruz, seviyorsan git konuş bence.</p>
            <p className="footer-mail"><a href="mailto:info@mertsistem.com">info@mertsistem.com</a></p>
            <NavLink className="btn btn-red" to="/iletisim">İletişime Geç</NavLink>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-social">
            <a href="https://instagram.com/mertsistemcom">
              <img src={instagram} alt="instagram" />
            </a>
            <a href="https://www.youtube.com/channel/UCCaYyS-IoTs53MnAbGLAvmw/">
              <img src={youtube} alt="youtube" />
            </a>
            <a href="https://facebook.com/mertsistemcom">
              <img src={facebook} alt="facebook" />
            </a>
            {/* <a href="https://twitter.com/mertsistem">
              <img src={twitter} alt="twitter" />
            </a> */}
            <a href="https://linkedin.com/company/mertsistemcom">
              <img src={linkedin} alt="linkedin" />
            </a>
            <a href="https://behance.net/mertsistemcom">
              <img src={behance} alt="behance" />
            </a>
          </div>
          <ul className="footer-menu">
            <h4>Keşfet</h4>
            <li>
              <NavLink activeClassName="activelink" to="/tanisalim">Tanışalım</NavLink>
            </li>
            <li>
              <NavLink activeClassName="activelink" to="/neler-yaptik">Neler Yaptık</NavLink>
            </li>
            <li>
              <NavLink activeClassName="activelink" to="/heycanlaniyoruz">Heycanlanıyoruz</NavLink>
            </li>
            <li>
              <NavLink activeClassName="activelink" to="/iletisim">İletişim</NavLink>
            </li>
            <p className="footer-address">Sağlık 1 Sok. Yıldız Apt. No:36/2 Kızılay / ANKARA</p>
          </ul>
        </div>
      </footer>
    )
  }
}
