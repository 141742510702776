import React, { Component } from 'react'
import {Helmet} from "react-helmet";

export default class Next extends Component {
  render() {
    return (
      <section>
				<Helmet>
					<title>Neler Yapmak İstiyoruz | Mert Sistem</title>
					<meta name="description" content="Güzel işler yapacağımıza inanıyoruz. İnanmakla kalmıyor hayata geçiriyoruz." />
				</Helmet>
				<div className="container main-hero">
					<div>
						<h1>
							Beraber Süperkulâde <br/> İşler Yapabiliriz.
						</h1>
						<p>
							Biz inanıyoruz! Bu topraklardan ne çıkmaz ki?
						</p>
					</div>
				</div>
				<div className="container-fluid next-page">
					<div className="container">
						<div className="boxes">
							<div className="box">
								<h2>
									01. <br/>
									keşfet
								</h2>
								<p>
									Ürünü kim kullanacak? Kimler nelerle uğraşıyor?
								</p>
							</div>
							<div className="box">
								<h2>
									02. <br/>
									tasarla
								</h2>
								<p>
									Kullanıcı odaklı, gereksiz süsten uzak, ihtiyaca yönelik.
								</p>
							</div>
							<div className="box">
								<h2>
									03. <br/>
									test et
								</h2>
								<p>
									Denemeden, kontrol etmeden olmaz.
								</p>
							</div>
							<div className="box">
								<h2>
									04. <br/>
									kodla
								</h2>
								<p>
									Doğru teknoloji çok önemli, sadece yeni diye kullanmayalım. 
								</p>
							</div>
						</div>
						<div className="final-step">
							<h2>
								05. <br/>
								yayınla
							</h2>
							<p>
								Derdimizi herkes anlasın, o ufak tefek böcekler için de elimizden geleni yapıyoruz. 
							</p>
						</div>
					</div>
				</div>
			</section>
    )
  }
}
