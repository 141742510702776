import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import heroImage from '../images/hero-images.svg';
import circleArrow from '../images/circle-arrow.svg';

import user from '../images/user.svg';
import design from '../images/design.svg';
import growth from '../images/growth.svg';
import research from '../images/research.svg';
import technology from '../images/technology.svg';

import akkaymakMockup from '../images/referans/akkaymak-mockup.jpg';
import mertburoMockup from '../images/referans/mert-buro-mockup.jpg';

import blogPost1 from '../images/blog/rocketlab-pickering.jpg';
import blogPost2 from '../images/blog/aventura-mall-in-america.jpg';


export default class Home extends Component {
  render() {
    return (
      <section>
				<div className="container home-page">
					<div className="hero-text">
						<h1>
							Gücünüze Güç Katmak İçin!
						</h1>
						<p>
							Ankara’da işinize değer katan Web Tasarım ve Dijital Çözümler üreten güvenilir iş ortağınız.
							Hızlı. Kullanıcı Odaklı. İnovatif.
						</p>
						<ul className="hero-buttons">
							<li>
								<Link className="btn btn-white" to="/iletisim">Beraber Çalışalım?</Link>
							</li>
							<li>
              	<Link className="btn" to="/tanisalim">Tanışalım</Link>
							</li>
						</ul>
					</div>
					<div className="hero-images">
						<img src={heroImage} alt="hero-images" />
					</div>
				</div>
				<div className="container-fluid featured-works">
					<div className="container">
						<h2>
							Öne Çıkan İşler
						</h2>
						<div className="boxes">
							<div className="box">
								<Link to="/akkaymak-kirtasiye">
									<img src={akkaymakMockup} alt="akkaymak-kirtasiye-mockup" />
									<h3>
										Akkaymak Kırtasiye
									</h3>
								</Link>
								
							</div>
							<div className="box">
								<Link to="/mert-buro">
									<img src={mertburoMockup} alt="mert-buro-mockup" />
									<h3>
										Mert Büro
									</h3>
								</Link>
							</div>
						</div>
					
						<div className="btn-box">
							<Link className="btn btn-white" to="/neler-yaptik">
								Diğer İşleri İncele
							</Link>
						</div>
					</div>
				</div>
				<div className="container-fluid tech-stack">
					<div className="container">
						<h2>
							Neler Yapıyoruz?
						</h2>
						<div className="boxes">
							<div className="box">
								<img src={user} alt="kullanıcı-odaklı" />
								<h3>
									Kullanıcı Odaklı
								</h3>
							</div>
							<div className="box">
								<img src={research} alt="yerinde-araştırma" />
								<h3>
									Yerinde Araştırma
								</h3>
							</div>
							<div className="box">
								<img src={design} alt="doğru-tasarım" />
								<h3>
									Doğru Tasarım
								</h3>
							</div>
							<div className="box">
								<img src={technology} alt="mantıklı-teknoloji" />
								<h3>
									Mantıklı Teknoloji
								</h3>
							</div>
							<div className="box">
								<img src={growth} alt="büyümeye-yönelik" />
								<h3>
									Büyümeye Yönelik
								</h3>
							</div>
						</div>
					</div>
				</div>
				<div className="container-fluid definition">
					<div className="container">
						<h2>
							Endüstri lideri markalar ve yeni başlayanlar için trafik, etkileşim ve dönüşüm sağlayan web siteleri kuran bir web tasarım ve geliştirme şirketiyiz.
						</h2>
					</div>
				</div>
				<div className="container-fluid featured-blog">
					<div className="container">
						<h2>
							Bilgi Paylaştıkça Çoğalır
						</h2>
						<div className="boxes">
							<div className="box">
								<Link to="/growth-hacking-ile-buyume">
									<img src={blogPost1} alt="hero-images" />
									<h3>
										Growth Hacking'le mi büyüsek? Büyüyelim de!
									</h3>
									<p>
										İlk olarak Sean Ellis tarafından 2010 yılında ortaya atılan Grotwh Hacking, bir markanın büyümesi için yapılması gereken faaliyetleri bütünsel olarak tanımlayan bir kavramdır. Özellikle teknoloji girişimlerinin müşteri sayısını, satışlarını ve marka bilinirliğini arttırmak için kullanılır.
									</p>
								</Link>
							</div>
							<div className="box">
								<Link to="/e-ticaret-yapmiyorum-ssl-ihtiyaci">
									<img src={blogPost2} alt="hero-images" />
									<h3>
										E-Ticaret yapmıyorum! Neden SSL'e ihtiyacım var?
									</h3>
									<p>
										Zaman gibi teknolojinin de hızla ilerlemesi ve gereksinimlerin farklılaşması sonucu, alınması gereken önlemler veya ihtiyaçtan dolayı takip edilmesi gereken serüvenler ortaya çıkıyor. Bu yazımızı SSL nedir, niçin önemlidir ve neden e-ticaret yapmadığımız halde SSL’e sahip olmalıyız şeklinde ele alıyoruz. 
									</p>
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="container-fluid cta">
					<div className="container">
						<Link to="/neler-yaptik">
							<div className="box">
								<h2>
									Size göstermek istediğimiz daha çok fazla şey var!
								</h2>
								<img src={circleArrow} alt="circle-arrow" />
							</div>
						</Link>
					</div>
				</div>
			</section>
    )
  }
}
