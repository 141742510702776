import React, { Component } from 'react'
import {Helmet} from "react-helmet";
import blogPost2 from '../../images/blog/aventura-mall-in-america.jpg';

export default class Post2 extends Component {
  render() {
    return (
      <section>
				<Helmet>
					<title>E-Ticaret yapmıyorum! Neden SSL'e ihtiyacım var? | Mert Sistem</title>
					<meta name="description" content="Zaman gibi teknolojinin de hızla ilerlemesi ve gereksinimlerin farklılaşması sonucu, alınması gereken önlemler veya ihtiyaçtan dolayı takip edilmesi gereken serüvenler ortaya çıkıyor." />
				</Helmet>
				<div className="container-fluid single-blog-post">
					<div className="container">
						<h1>
              E-Ticaret yapmıyorum! Neden SSL'e ihtiyacım var?
						</h1>
            <img src={blogPost2} alt=""/>
            <p>
            Zaman gibi teknolojinin de hızla ilerlemesi ve gereksinimlerin farklılaşması sonucu, alınması gereken önlemler veya ihtiyaçtan dolayı takip edilmesi gereken serüvenler ortaya çıkıyor. Bu yazımızı SSL nedir, niçin önemlidir ve neden e-ticaret yapmadığımız halde SSL’e sahip olmalıyız şeklinde ele alıyoruz. <br/><br/>
Öncelikle, SSL tam olarak nedir? “SSL” Secure Socket Layer (Güvenli Yuva Katmanı)’ın kısaltılmış halidir. Basitçe açıklamak gerekirse, küçük işletmeler müşterilerine web sitelerinde güvenle dolaşabileceklerini, hizmet veya ürün satın alabileceklerini ve bilgilerini paylaşabileceklerini bu yolla belirtirler. Sitenize SSL sertifikası eklemek bu tür aktiviteler için güvenli bir bağlantı oluşturur.<br/><br/>
SSL ilk ortaya çıktığında çoğunlukla sadece IT profesyonellerinin önemsediği ve anladığı bir güvenlik protokolüydü. Günümüzde ise SSL sadece bir güvenlik protokolü olmanın çok ötesine geçmiş durumda. Pazarlamacılar ve iş geliştiricileri artık SSL ürünlerine iyice aşina olmuş durumda ve SSL’i müşterilerin güvenini kazanma, güvenli işlem gerçekleştirilebilmesini sağlama, marka imajını geliştirme ve web sitelerinin arama sıralamalarında üstlerde yer alması gibi konularda önemli bir çözüm olarak kullanıyorlar. SSL artık her web sitesine entegre edilmesi gereken güçlü bir pazarlama aracı konumunda.<br/><br/>
Müşteri kazanımı web sitelerinin güven faktöründen doğrudan etkilediğini bilmekteyiz. Güvenilirliği ve itibarı arttırmak daha fazla müşteri kazanılmasını sağlar. Bir web sayfasını ziyaret eden kişiler o sayfaya güvenip güvenemeyeceklerini bilemiyorlarsa o sitenin sahibi müşterilerini, potansiyel müşterilerini ve nihayetinde şirketlerini kaybetme tehlikesiyle karşı karşıya demektir. Web sayfalarına SSL güvenliğini entegre etmek ziyaretçileri o sayfaların ve o sayfada yapılan işlemlerin güvenli olduğu konusunda ikna edici olur.<br/><br/>
SSL Google’ın arama sıralaması algoritmasında doğrudan bir rol oynar. Google iki sene önce ilk kez tüm siteleri ziyaretçilerinin bilgilerini SSL sertifikalarıyla güvence altına almasını teşvik eden bir sıralama göstergesi olarak [HTTPS kullanımının](https://ihs.com.tr/blog/google-https-sayfalari-indexlemeye-basladi/) başladığını duyurmuştu. Hatta sitenin kişisel bilgileri toplayıp toplamadığına bakmaksızın, SSL sertifikası olan siteleri ödüllendirmek için “HTTPS Everywhere” adında bir inisiyatif başlatmıştı. SSL’in ziyaretçi güvenini arttırdığını, ziyaretçi güveninin ise müşteri kazanım oranlarını, tek sayfalık ziyaret oranlarını ve sayfada geçirilen süreleri doğrudan etkilediğini bildiğimiz için web sitelerinde SSL entegrasyonunun SEO sıralamasında daha üstlerde yer almasını sağladığını açıkça söyleyebiliriz.<br/><br/>
Önde gelen arama ve içerik performansı platformlarından biri olan Searchmetrics SSL’in sıralamalar üzerindeki etkisi üzerine bir çalışma gerçekleştirmişti. Bu çalışmada SSL sertifikası olan sitelerin SEO görünürlüğü anlamında olumlu kazanımları olduğu görüldü. Çalışmada belirli bazı domainler incelenmiş ve bu domainlerin SEO görünürlüklerini SSL kriptolaması sayesinde kazandığı çıkarımı yapılmıştı<br/><br/>
SSL sertifikasının sitelerin arama sonuçlarında doğrudan yükselmesini sağlaması söz konusu değil elbette ama SEO için bir kriter olması, ileride SSL sertifikasına sahip sitelerin sayısının artmasını sağlayabilir. Google sürekli yenilikler yaparak sitelerin de değişime ve gelişime ayak uydurmasını sağlıyor. Kısa bir zaman öncede, mobil uyumlu sitelerin Google sonuçlarında üst sıralara taşınacağını söylemişti. Akıllı telefonlardan yapılan aramalarda, mobil uyumlu olan siteler ön planda olacak.<br/><br/>
Sonuç olarak SSL kurulu olan web sitelerinde, kullanıcıların kişisel verilerini korunması bir yana, sağladığı birçok fayda ile gelişen teknolojiyi kasıtlı olarak takip etmemek veya ziyaretçilerinizi topraklarınızda daha keyifli ve verimli zamanlar geçirmelerini sağlamak tamamen konu ile ilgili bilinçlenmeyle alakalı olduğunu düşünmekteyiz. SSL hakkında daha fazla bilgi almak ve danışmak için iletişimden adreslerimizden bizlerle irtabat kurabilirsiniz.<br/><br/>
            </p>
					</div>
				</div>
			</section>
    )
  }
}
