import React, { Component } from 'react';
import {Helmet} from "react-helmet";

import whoIs from '../images/who-is.svg';
import whoNot from '../images/who-not.svg';
import whatWeDo from '../images/what-we-do.svg';

export default class About extends Component {
  render() {
    return (
      <section>
				<Helmet>
						<title>Tanışalım | Mert Sistem</title>
						<meta name="description" content="Hiç bir şeye ihtiyacımız yok, yalnız bir şeye ihtiyacımız vardır; çalışkan olmak! - M. Kemal ATATÜRK" />
				</Helmet>
				<div className="container main-hero">
					<div>
						<h1>
							Tanışmak için sabırsızlanıyoruz!
						</h1>
						<p>
							Hiç bir şeye ihtiyacımız yok, yalnız bir şeye ihtiyacımız vardır; çalışkan olmak! - M. Kemal ATATÜRK						
						</p>
					</div>
				</div>
				<div className="container-fluid about-page">
					<div className="container">
						<div className="boxes">
							<div className="box">	
								<div className="title">
									<img src={whoIs} alt="kimiz-ikon" />
									<h3>
										Kimiz
									</h3>
								</div>
								<ul>
									<li>İnsanız</li>
									<li>Planlayıcı</li>
									<li>Tasarımcı</li>
									<li>Ön Yüz Geliştirici</li>
									<li>Marka Sorumlusu</li>
									<li>Dijital Danışman</li>
									<li>Yazılım Mimarı</li>
									<li>Sistem Uzmanı</li>
									<li>Arka Plan Geliştirici</li>
								</ul>
							</div>
							<div className="box">	
								<div className="title">
									<img src={whoNot} alt="kim-degiliz-ikon" />
									<h3>
										Kim Değiliz
									</h3>
								</div>
								<ul>
									<li>Robot</li>
									<li>Serbest Çalışan</li>
									<li>Dinazor <span role="img" aria-label="dinosaur">🦖</span></li>
									<li>Köle</li>
									<li>Yeğeniniz</li>
								</ul>
							</div>
							<div className="box">	
								<div className="title">
									<img src={whatWeDo} alt="neler-yapariz-ikon" />
									<h3>
										Neler Yaparız
									</h3>
								</div>
								<ul>
									<li>Marka Konumlandırma</li>
									<li>Marka Araştırma ve Planlama</li>
									<li>Arayüz Tasarım</li>
									<li>Kullanıcı Deneyimi Tasarımı</li>
									<li>Web Uygulamaları</li>
									<li>Mobil Uygulamalar</li>
									<li>Marka Bilinirliği</li>
									<li>Arama Motoru Optimizasyonu</li>
									<li>Arama Motoru Pazarlama</li>
									<li>Sosyal Medya Pazarlama</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="container-fluid about-what-we-do">
					<div className="web">
						<h2>
							Web
						</h2>
						<p>
							Sanal topraklarda varlığınızı sürdürebilmek hiç bu kadar zor ve aynı zamanda hiç bu kadar kolay olmamıştı. Mesele hiç bir zaman toprak sahibi olmak değildi. Sürdürülebilir, verimli ve kullanıcı odaklı çözümlere hayat vermek bizim gaye-i hayalimiz…
						</p>
					</div>
					<div className="web-other">
						<div className="design">
							<h2>
								Tasarım
							</h2>
							<p>
								Sadece göze güzel görünmesi değil; aynı zamanda kolay kullanılabilir, kim için yapılmış, hangi şartlarda kullanılacak, neden kullanılacak sorularına cevap veren bir tasarım anlayışını benimsiyoruz. 
							</p>
						</div>
						<div className="design-other">
							<div className="branding">
								<h2>
									Markalama
								</h2>
								<p>
									Nereye bakarlar biliyoruz, markanızı doğru yerde ve zaman konumlandırıyoruz. 
								</p>
							</div>
							<div className="mobile">
								<h2>
									Mobil
								</h2>
								<p>
									Bilgisayar açmak her zaman kullanışlı olmuyor değil mi?
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
    )
  }
}
