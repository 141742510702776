import React, { Component } from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';

import akkaymakMockup from '../images/referans/akkaymak-mockup.jpg';
import mertburoMockup from '../images/referans/mert-buro-mockup.jpg';

export default class Works extends Component {
  render() {
    return (
      <section>
				<Helmet>
					<title>Neler Yaptık | Mert Sistem</title>
					<meta name="description" content="Yaptıklarımız Yapacaklarımızın Teminatıdır. Her zaman üzerine koyabileceğimiz işler yapmaya çalışıyoruz." />
				</Helmet>
				<div className="container main-hero">
					<div>
						<h1>
							Yaptıklarımız Yapacaklarımızın Teminatıdır.
						</h1>
						<p>
							Her zaman üzerine koyabileceğimiz işler yapmaya çalışıyoruz.
						</p>
					</div>
				</div>
				<div className="container-fluid works-page">
					<div className="job job-1">
						<div className="container">
							<img src={akkaymakMockup} alt="akkaymak-mockup" />
							<div className="detail">
								<h3>
									Akkaymak Kırtasiye
								</h3>
								<p>
									2016'dan beri beraber çok güzel işler ortaya koyduk. 2019 Mart ayında yenilenmek için kollarımızı sıvadık. "Instagram'a story atmak kadar kolay..." sözüne odaklanıp her şeyi mağazada çalışan herkesin kullanabileceği formatta yapıyoruz.
								</p>
								<div className="buttons">
									<Link className="btn btn-white" to="/akkaymak-kirtasiye">
										Detayları İncele
									</Link>
									<a href="https://akkaymak.com.tr" className="btn">
										Ziyaret Et
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="job job-2">
						<div className="container">
							<img src={mertburoMockup} alt="mert-buro-mockup" />
							<div className="detail">
								<h3>
									Mert Büro
								</h3>
								<p>
									Ankara'nın en büyük büro makineleri kiralama firmasına, 2015'de yapmış olduğumuz websitesini 2019'da yeniledik. Dinamik bir görünüm, 2019 kullanım şartlarına uygun mobil öncelikli bir tasarıma kavuşmuş oldu. 
									Daha fazla müşteriye ulaşmak için SEO/SEM çalışıyoruz.
								</p>
								<div className="buttons">
									<Link className="btn btn-white" to="/mert-buro">
										Detayları İncele
									</Link>
									<a href="https://mertburo.com" className="btn">
										Ziyaret et
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="join-us">
						<div className="container">
							<h2>
								Size de burayı ayırdık!
							</h2>	
							<Link className="btn btn-white" to="/iletisim">
								Hemen Başla
							</Link>
						</div>
					</div>
				</div>
			</section>
    )
  }
}
