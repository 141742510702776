import React, { Component } from 'react'
import logo from '../images/logo.svg';
import menu from '../images/menu.svg';

import { NavLink } from 'react-router-dom';

export default class Header extends Component {
  constructor() {
    super();
    this.state = {
      isExpanded: false,
    };
  }	
  
  handleToggle(e) {
    e.preventDefault();
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }
  
  render() {
    const { isExpanded } = this.state;		
    return (
      <header>
        <div className="header container">
          <div>
            <NavLink to="/">
              <img src={logo} className="header-logo" alt="logo" />
            </NavLink>
          </div>
          <div className="mobile-icon"  onClick={e => this.handleToggle(e)}>
            <img src={menu} className="header-logo" alt="logo" />
          </div>
          <ul className={`header-menu ${isExpanded ? 'header-isExpanded' : ""}`} onClick={e => this.handleToggle(e)}>
            <li>
              <NavLink activeClassName="activelink" to="/tanisalim">Tanışalım</NavLink>
            </li>
            <li>
              <NavLink activeClassName="activelink" to="/neler-yaptik">Neler Yaptık</NavLink>
            </li>
            <li>
              <NavLink activeClassName="activelink" to="/heycanlaniyoruz">Heycanlanıyoruz</NavLink>
            </li>
            <li>
              <NavLink activeClassName="activelink" to="/iletisim">İletişim</NavLink>
            </li>
          </ul>
        </div>
      </header>
    )
  }
}
